import { catchError, combineLatest, Observable, of, tap } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { PmCorePageTextService } from '@pm/core/data';
import { PageText } from '@pm/core/utils';

@Component({
  selector: 'pm-core-seo-text',
  templateUrl: './pm-core-seo-text.component.html',
  styleUrls: ['./pm-core-seo-text.component.scss'],
  standalone: false,
})
export class PmCoreSeoTextComponent implements OnInit {
  @Input() noHeader = false;
  @Input() pageTitle!: string;

  page$: Observable<[PageText | undefined, unknown]> | undefined;
  page: PageText | undefined;

  constructor(
    private _page: PmCorePageTextService,
    private _router: Router,
    private _meta: Meta,
    private _title: Title,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.page$ = combineLatest([
      this._page.getPageText$(this._router.url).pipe(
        catchError(() => {
          return of(undefined);
        }),
      ),
      this._page.getRegionalDomains$(),
    ]).pipe(
      tap(([page]: [PageText | undefined, { [key: string]: string }]) => {
        this.page = page;
      }),
      tap(([page, data]: [PageText | undefined, { [key: string]: string }]) => {
        if (page) {
          this._setTitle(page);
          this._setMetatags(page);
        }
        this._setHreflangLinks(data);
      }),
    );
  }

  private _setTitle(page: PageText) {
    const metatags = page.metatags;
    const title = metatags.find((tag) => tag.property === 'title');
    if (title?.content && title?.content !== '~none~') {
      return this._title.setTitle(title.content);
    }

    if (this.appConfig.metaTitleTemplate && this.pageTitle) {
      return this._title.setTitle(
        this.appConfig.metaTitleTemplate.replace('@PAGE_NAME', this.pageTitle),
      );
    }

    if (this.appConfig.pageTitle) {
      return this._title.setTitle(this.appConfig.pageTitle);
    }
  }
  private _setMetatags(page: PageText) {
    const metatags = page.metatags
      .filter((tag) => tag.content !== '~none~' && tag.property !== 'title')
      .map((tag) => {
        return {
          name: tag.property,
          content: tag.content,
        };
      });

    const isDescriptionSet = metatags.some((tag) => tag.name === 'description');
    if (!isDescriptionSet) {
      const description = this._mapDescription();
      if (description) {
        metatags.push(description);
      }
    }

    const isOgTitleSet = metatags.some((tag) => tag.name === 'og:title');
    if (!isOgTitleSet && this.pageTitle) {
      metatags.push({
        name: 'og:title',
        content: this.pageTitle,
      });
    }

    const isOgDescriptionSet = metatags.some(
      (tag) => tag.name === 'og:description',
    );
    if (!isOgDescriptionSet) {
      const description = this._mapDescription();
      if (description) {
        metatags.push({
          name: 'og:description',
          content: description.content,
        });
      }
    }
    for (const tag of metatags) {
      this._meta.updateTag(tag);
    }

    this._setCanonical();
    this._setHtmlLang();
  }

  private _mapDescription() {
    if (this.appConfig.metaDescriptionTemplate && this.pageTitle) {
      return {
        name: 'description',
        content: this.appConfig.metaDescriptionTemplate.replace(
          '@PAGE_NAME',
          this.pageTitle,
        ),
      };
    }

    if (this.appConfig.pageDescription) {
      return {
        name: 'description',
        content: this.appConfig.pageDescription,
      };
    }
    return;
  }

  private _setCanonical() {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;

      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');

    const cleanedUrl = this._router.url.split('?')[0];
    const defaultDomain = `https://${this.appConfig.domain}`;
    const currentUrl = `${defaultDomain}${cleanedUrl}`;

    element.setAttribute('href', currentUrl);
  }

  private _setHtmlLang() {
    if (this.document) {
      this.document.documentElement.lang = this.appConfig.localeCode ?? 'en';
    }
  }
  /**
   * Set hreflang links
   */
  private _setHreflangLinks(data: { [key: string]: string }) {
    const head = this.document.getElementsByTagName('head')[0];

    const pairs: [string, unknown][] = Object.entries(data);
    const defaultDomain = `https://${this.appConfig.domain}`;

    const cleanedUrl = this._router.url.split('?')[0];
    const currentUrl = `${defaultDomain}${cleanedUrl}`;
    const xDefault = this.document.querySelector('link[hreflang="x-default"]');
    if (xDefault) {
      xDefault.setAttribute('href', currentUrl);
    } else {
      const defaultLink = this.document.createElement('link');
      defaultLink.setAttribute('rel', 'alternate');
      defaultLink.setAttribute('hreflang', 'x-default');
      defaultLink.setAttribute('href', currentUrl);
      head.appendChild(defaultLink);
    }

    for (const [code, regionalDomain] of pairs) {
      const regionalUrl = `https://${regionalDomain}${cleanedUrl}`;
      const existingLink = this.document.querySelector(
        `link[hreflang="${code}"]`,
      );
      if (existingLink) {
        existingLink.setAttribute('href', regionalUrl);
      } else {
        const link = this.document.createElement('link');
        link.setAttribute('rel', 'alternate');
        link.setAttribute('hreflang', code);
        link.setAttribute('href', regionalUrl);
        head.appendChild(link);
      }
    }
  }
}
