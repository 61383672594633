/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PmCoreSupportChatwootService } from './pm-core-support-chatwoot.service';
import { PmCoreSharkService } from '@pm/core/shark';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PmCoreSupportChatService {
  isChatwootExists = false;

  constructor(
    private readonly _chatwootService: PmCoreSupportChatwootService,
    private readonly _sharkService: PmCoreSharkService,
    @Inject(APP_CONFIG) private _config: AppConfig,
    @Inject(PLATFORM_ID) readonly platformId: string,
  ) {}

  injectChat(): void {
    if (this._config.chatwoot) {
      this._chatwootService.injectChat();
    } else if (this._config.shark) {
      this._sharkService.injectChat();
    }

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('chatwoot:ready', () => {
        this.isChatwootExists = true;
      });
    }
  }

  openChat(): void {
    if (this._isChatwootExists()) {
      this._openChatwoot();
    } else if (this._isSharkChatExists()) {
      this._openSharkChat();
    }
  }

  setUserData(userData: { name: string; email: string; phone: string }): void {
    try {
      if (
        this._isChatwootExists() &&
        Object.values(userData).some((value) => !!value)
      ) {
        (window as any).$chatwoot.setUser(userData.email, {
          name: userData.name,
          email: userData.email,
          phone_number: userData.phone,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
    }

    if (this._isSharkChatExists()) {
      (window as any)._udata = userData;
    }
  }

  private _isChatwootExists(): boolean {
    return window && (window as any).$chatwoot;
  }

  private _isSharkChatExists(): boolean {
    return window && (window as any).sharkWidget;
  }

  private _openChatwoot(): void {
    (window as any).$chatwoot.toggle('open');
  }

  private _openSharkChat(): void {
    (window as any).sharkWidget.openWidget();
  }
}
