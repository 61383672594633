import { combineLatest, firstValueFrom } from 'rxjs';

import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
import { Injector, NgModule, PLATFORM_ID, inject, provideAppInitializer } from '@angular/core';
import {
  TranslocoModule,
  TranslocoService,
  provideTransloco,
} from '@ngneat/transloco';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { REQUEST } from '@pm/core/utils';

import { TranslocoHttpLoader } from './transloco-loader';

export function setMainLanguage(
  injector: Injector,
  transloco: TranslocoService,
) {
  return function () {
    const config = injector.get(APP_CONFIG) as AppConfig;
    const platformId = injector.get(PLATFORM_ID);
    let langInPath;
    if (isPlatformBrowser(platformId)) {
      const location = injector.get(Location);
      langInPath = location.path().split('/')[1];
    } else {
      langInPath = injector.get(REQUEST).path.split('/')[1];
    }
    const lang = config.enabledLanguages?.includes(langInPath)
      ? langInPath
      : config.localeCode ?? 'en';

    transloco.setActiveLang(lang);

    if (config.localeCode === 'ar') {
      const document = injector.get(DOCUMENT);
      document.body.dir = 'rtl';
    }

    const loadLanguages$ = combineLatest(
      config.enabledLanguages?.map((lang) => transloco.load(lang)) ?? [
        transloco.load(lang),
      ],
    );

    return firstValueFrom(loadLanguages$);
  };
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [
          'zh',
          'tr',
          'sl',
          'hu',
          'lt',
          'nl',
          'de',
          'pl',
          'uk',
          'en',
          'pt-pt',
          'it',
          'fr',
          'el',
          'ro',
          'hi',
          'cs',
          'da',
          'sv',
          'es',
          'ar',
          'et',
          'ru',
        ],
        defaultLang: 'en',
        reRenderOnLangChange: true,
      },
      loader: TranslocoHttpLoader,
    }),
    provideAppInitializer(() => {
        const initializerFn = (setMainLanguage)(inject(Injector), inject(TranslocoService));
        return initializerFn();
      }),
  ],
})
export class TranslocoRootModule {}
