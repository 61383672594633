/* eslint-disable @typescript-eslint/no-explicit-any */
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter, map } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { PmCoreHotjarService } from '@pm/core/hotjar';
import { PmCoreLocaleRedirectService } from '@pm/core/locale';
import { PmCoreSeoSchemaService } from '@pm/core/seo';
import { PmCoreSupportChatService } from '@pm/core/support';

@Component({
    selector: 'profitmark-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  title = 'profitmark-eu';
  isDark = true;
  platformId!: string;

  navigation$ = this._router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this._route.snapshot),
      map((route: ActivatedRouteSnapshot) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
    )
    .subscribe((event: any) => {
      this.isDark = event.data.state === 'dark';
    });

  constructor(
    private readonly _router: Router,
    private readonly _support: PmCoreSupportChatService,
    private readonly _schema: PmCoreSeoSchemaService,
    private readonly _gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) platformId: string,
    private readonly _hotjar: PmCoreHotjarService,
    private readonly _route: ActivatedRoute,
    private readonly _locale: PmCoreLocaleRedirectService,
  ) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this._gtmService.addGtmToDom();
      this._locale.redirectOnInitialLoad();
      this._support.injectChat();
      this._hotjar.init();
    }

    this._schema.attachAllSchemas();
  }

  async showCookieConsent(): Promise<void> {}
}
