import {
  BehaviorSubject,
  Subject,
  catchError,
  from,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import {
  PmAuthConfirmationMode,
  PmAuthService,
  RegistrationRequest,
} from '@pm/auth/utils';

import { PmAuthDataService } from '../services';

export class PmAuthBloc {
  isLoggined$ = this._authService.isLoggedIn$;

  error$ = new Subject<string>();
  erroredFields$ = new Subject<string[]>();
  success$ = new Subject<boolean>();

  processing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _authDrupalService: PmAuthDataService,
    private _authService: PmAuthService,
  ) {}

  submitLoginForm(login: string, password: string) {
    this._authDrupalService
      .submitLoginForm$(login, password)
      .pipe(take(1))
      .subscribe({
        next: (v) => this._authService.setTokens(v),
        error: this.mapAuthError,
      });
  }

  submitRegistrationForm(payload: RegistrationRequest) {
    const { phone, password } = payload;
    this.processing$.next(true);
    this._authDrupalService
      .submitRegistrationForm$(payload)
      .pipe(
        switchMap(() =>
          this._authDrupalService.submitLoginForm$(phone, password ?? ''),
        ),
        catchError((error) => {
          this.mapAuthError(error);
          this.processing$.next(false);
          return throwError(() => error);
        }),
      )
      .subscribe({
        next: (v) => this._authService.setTokens(v),
      });
  }

  resetPassword(login: string) {
    this._authDrupalService
      .resetPassword$(login)
      .pipe(take(1))
      .subscribe({
        next: (v) => this.success$.next(true),
        error: this.mapAuthError,
      });
  }

  logout() {
    this._authService.signOut();
  }

  redirectToDashboard() {
    this._authService.openDashboard();
  }

  refreshToken() {
    from(this._authService.getRefreshToken())
      .pipe(
        switchMap((refreshToken) =>
          this._authDrupalService.refreshAccessToken$(refreshToken as string),
        ),
        take(1),
      )
      .subscribe({
        next: (v) => this._authService.setTokens(v),
        error: this.mapAuthError,
      });
  }

  confirmUser(type: PmAuthConfirmationMode, payload: RegistrationRequest) {
    return this._authDrupalService
      .confirmUser$(type, payload)
      .pipe(take(1))
      .subscribe({
        next: (v) => this.success$.next(true),
        error: this.mapAuthError,
      });
  }

  sendConfirmCode(code: string) {
    return this._authDrupalService
      .sendConfirmCode(code)
      .pipe(take(1))
      .subscribe({
        next: (v) => this._authService.setTokens(v),
        error: this.mapAuthError,
      });
  }

  mapAuthError = (error: HttpErrorResponse) => {
    const { message, status, error: err } = error;

    switch (status) {
      case 401:
        this.error$.next('Invalid credentials');
        break;
      case 403:
        this.error$.next('Invalid credentials');
        break;
      case 404:
        this.error$.next('Invalid credentials');
        break;
      case 500:
        this.error$.next('Server error');
        break;
      case 422: {
        const messages = err?.message?.split('\n');
        const fieldNamePattern = /(?<=\n)(\w+)/g;
        const fieldNames = [
          ...(err?.message?.matchAll(fieldNamePattern) ?? []),
        ].map((match) => match[1]);

        this.erroredFields$.next(
          fieldNames.map((f) => {
            switch (f) {
              case 'name':
                return 'phone';
              case 'mail':
                return 'email';
            }
            return f;
          }),
        );

        if (messages?.length) {
          const fullFieldNamePattern = /^(?:\w+)(?:\.\d+\.\w+)?:/gm;
          messages
            .filter(Boolean)
            .filter((m: string) => !m.includes('Unprocessable'))
            .map((m: string) => m.replace(fullFieldNamePattern, ''))
            .forEach((m: string) => this.error$.next(m));
        } else {
          this.error$.next('Please check inputed information');
        }

        break;
      }
      default:
        this.error$.next('Problem with authorization. Please try again later.');
        break;
    }
  };
}
