import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  computed,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { PmAuthBloc, PmAuthDataService } from '@pm/auth/data';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { PmCoreLocaleRedirectService } from '@pm/core/locale';
import { Observable } from 'rxjs';

@Component({
    selector: 'pm-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {
  bloc = new PmAuthBloc(this._authDrupalService, this._authService);
  @Input()
  theme: 'light' | 'dark' = 'dark';

  forcedTheme!: 'light' | 'dark' | undefined;

  contactEmail = this.appConfig.contactEmail;
  contactPhone = this.appConfig.contactNumber;
  socialLinks = this.appConfig.socialLinks;
  messangersLinks = this.appConfig.messangersLinks;

  isLanguagesVisible = computed(() => Boolean(this.appConfig.enabledLanguages));
  language = toSignal(this._translation.langChanges$);

  /**
   * t(ui.english, ui.ukraninan, ui.moscowian)
   */
  languages = [
    { name: 'ui.english', code: 'en', short: 'EN' },
    { name: 'ui.ukraninan', code: 'uk', short: 'UA' },
    { name: 'ui.moscowian', code: 'ru', short: 'ru', strike: true },
  ];

  isLoggedIn$ = this._authService.isLoggedIn$;
  translations$!: Observable<unknown>;

  isBlogEnabled: boolean | undefined = this.appConfig.isBlogEnabled;

  isSticked = false;

  readonly STICKED_THRESHOLD = 43;

  constructor(
    private _authService: PmAuthService,
    private _authDrupalService: PmAuthDataService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly _translation: TranslocoService,
    private readonly _router: Router,
    private readonly _locale: PmCoreLocaleRedirectService,
    @Inject(PLATFORM_ID) public platformId: string,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this._detectSticked();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this._detectSticked();
  }

  changeLanguage(lang: string) {
    this._locale.setLocaleSelected(lang);
    this._locale.navigateWithLocale(this._router.url, lang);
  }

  private _detectSticked() {
    if (window.scrollY > this.STICKED_THRESHOLD) {
      this.isSticked = true;
      this.forcedTheme = 'light';
    } else {
      this.isSticked = false;
      this.forcedTheme = undefined;
    }
  }
}
