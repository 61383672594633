import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { APP_CONFIG, AppConfig } from '@pm/config';

@Component({
    selector: 'pm-ui-mobile-links',
    imports: [CommonModule, TranslocoModule],
    templateUrl: './pm-ui-mobile-links.component.html',
    styleUrls: ['./pm-ui-mobile-links.component.scss']
})
export class PmUiMobileLinksComponent {
  constructor(@Inject(APP_CONFIG) public config: AppConfig) {}
}
