export const COMPANY_ADDRESS = {
  es: {
    name: 'Profitmark International SL',
    country: 'es',
    address: 'Carretera San Vicente del Raspeig, Alicante, 03690',
    email: 'support@profitmark.eu',
  },
  eu: {
    name: 'Profitmark International SL',
    country: 'es',
    address: 'Carretera San Vicente del Raspeig, Alicante, 03690',
    email: 'support@profitmark.eu',
  },
  us: {
    name: 'Profitmark inc.',
    country: 'us',
    address: '16192 Coastal Highway Lewes, Delaware 19958 ',
    email: 'support@profitmark.eu',
  },
  lt: {
    name: 'Profitmark UAB',
    country: 'lt',
    address: 'Eisiskiu Sodu 18-oji street, 11, LT-02194, Vilnius, Lithuania',
    email: 'support@profitmark.eu',
  },
  ua: {
    name: 'LLC Profitmark',
    country: 'ua',
    address: 'Hrushetska street, 9a, Ukraine, Kyiv, 03113',
    email: 'support@profitmark.ua',
  },
};
