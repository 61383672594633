/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { DOCUMENT } from '@angular/common';
import { chatwootScript } from '../models/pm-core-support.defaults';

@Injectable({
  providedIn: 'root',
})
export class PmCoreSupportChatwootService {
  isBrowser!: boolean;
  private _renderer2: Renderer2;
  constructor(
    @Inject(PLATFORM_ID) readonly platformId: string,
    @Inject(DOCUMENT) private _document: Document,
    private _authService: PmAuthService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private rendererFactory: RendererFactory2,
  ) {
    this._renderer2 = this.rendererFactory.createRenderer(null, null);
    this.isBrowser = this.platformId === 'browser';
  }

  async injectChat() {
    if (this.isBrowser && this.appConfig.chatwoot) {
      const chat = this._renderer2.createElement('script');
      chat.text = chatwootScript(this.appConfig.chatwoot);

      this._renderer2.appendChild(this._document.body, chat);

      window.addEventListener('chatwoot:ready', () => {
        this.setUserDataFromToken();
      });
    }
  }

  async setUserDataFromToken() {
    try {
      const decoded = await this._authService.getDecodedToken();
      if (decoded && decoded.drupal) {
        (window as any).$chatwoot.setUser(decoded.drupal.uid, {
          email: decoded.drupal.email,
          name: decoded.drupal.displayname,
          phone_number: decoded.drupal.phone,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }
}
