import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { PmCoreSeoModule } from '@pm/core/seo';
import { PmFeedbackFormModule } from '@pm/feedback/form';
import { PmUiSectionHeroComponent } from '@pm/ui/elements';

@Component({
    selector: 'pm-core-page-default',
    imports: [
        CommonModule,
        TranslocoModule,
        PmCoreSeoModule,
        BreadcrumbModule,
        PmFeedbackFormModule,
        PmUiSectionHeroComponent
    ],
    templateUrl: './pm-core-page-default.component.html',
    styleUrls: ['./pm-core-page-default.component.scss']
})
export class PmCorePageDefaultComponent {
  items: MenuItem[] | undefined;
  home: MenuItem | undefined;
}
