/* eslint-disable @typescript-eslint/no-explicit-any */
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';
import { ObjectUtils } from 'primeng/utils';
import { mergeMap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, Input, Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { PmAuthBloc, PmAuthDataService } from '@pm/auth/data';
import { PmAuthService } from '@pm/auth/utils';
import { PmCoreLocaleLocalizePathPipe } from '@pm/core/locale';

import { PmHeaderLogoComponent } from '../pm-header-logo/pm-header-logo.component';
import { PmHeaderMegamenuMapperService } from './services/pm-header-megamenu-mapper.service';

@Component({
    selector: 'pm-header-megamenu',
    imports: [
        CommonModule,
        MegaMenuModule,
        TranslocoModule,
        MenubarModule,
        ButtonModule,
        PmHeaderLogoComponent,
        PmCoreLocaleLocalizePathPipe
    ],
    templateUrl: './pm-header-megamenu.component.html',
    styleUrls: ['./pm-header-megamenu.component.scss']
})
export class PmHeaderMegamenuComponent {
  bloc = new PmAuthBloc(this._authDrupalService, this._authService);

  @Input()
  theme: 'light' | 'dark' = 'light';

  @Input()
  isSticked = false;

  isAuthorized = toSignal(this.bloc.isLoggined$);

  currentLanguage = toSignal(
    this._transloco.langChanges$.pipe(
      mergeMap((lang) => this._transloco.selectTranslation(lang)),
    ),
  );

  items: Signal<MegaMenuItem[]> = computed(() => {
    this.currentLanguage();
    return this._menuItems.getMainMenuItems();
  });

  mobileMenu: Signal<MenuItem[]> = computed(() => {
    this.currentLanguage();
    return this._menuItems.getMobileMenuItems();
  });

  constructor(
    private readonly _transloco: TranslocoService,
    private _authService: PmAuthService,
    private _authDrupalService: PmAuthDataService,
    private readonly _menuItems: PmHeaderMegamenuMapperService,
  ) {}

  getItemProp(processedItem: any, name: string, params: any | null = null) {
    return processedItem
      ? ObjectUtils.getItemValue(processedItem[name], params)
      : undefined;
  }

  getItemLabel(item: any) {
    return this.getItemProp(item, 'label');
  }

  isItemGroup(processedItem: any): boolean {
    return ObjectUtils.isNotEmpty(processedItem.items);
  }

  goToDashboard() {
    this._authService.openDashboard();
  }

  signOut() {
    this._authService.signOut();
  }
}
