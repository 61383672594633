import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  DrupalUserEntity as BaseDrupalUserEntity,
  JwtToken,
  PmAuthConfirmationMode,
  RegistrationRequest,
} from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';

interface DrupalUserEntity extends BaseDrupalUserEntity {
  [key: string]: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class PmAuthDataService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private _http: HttpClient,
  ) {}

  submitLoginForm$(login: string, password: string) {
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa(login + `:` + password),
    });
    return this._http.get<JwtToken>(this.appConfig.api + '/jwt/token', {
      headers,
      params: {
        _format: 'json',
      },
    });
  }

  submitRegistrationForm$(payload: RegistrationRequest) {
    const localeCode = this._getLocaleCode();
    return this._http.post<DrupalUserEntity>(
      this.appConfig.api + `/${localeCode}/user/register`,
      this._mapToDrupalUser(this._mapUserRegistrationRequest(payload)),
      {
        params: {
          _format: 'json',
        },
      },
    );
  }

  resetPassword$(login: string) {
    return this._http.patch<{
      status: string[];
    }>(
      this.appConfig.api + '/api/v1/user',
      {
        reset: {
          field_phone: login,
        },
      },
      {
        params: {
          _format: 'json',
        },
      },
    );
  }

  createSsoToken$() {
    return this._http.get<string>(this.appConfig.api + '/api/v1/sso');
  }

  refreshAccessToken$(refreshToken: string) {
    return this._http.get<JwtToken>(this.appConfig.api + '/jwt/refresh', {
      params: {
        _format: 'json',
      },
      headers: {
        Authorization: 'Bearer ' + refreshToken,
      },
    });
  }

  confirmUser$(type: PmAuthConfirmationMode, payload: RegistrationRequest) {
    return this._http.post<{ message: string }>(
      this.appConfig.api + '/api/v1/user/confirmation',
      {
        type,
        data: this._mapUserRegistrationRequest(payload),
      },
      {
        params: {
          _format: 'json',
        },
      },
    );
  }

  sendConfirmCode(code: string) {
    return this._http.patch<JwtToken>(
      this.appConfig.api + '/api/v1/user/confirmation',
      {
        code,
      },
      {
        params: {
          _format: 'json',
        },
      },
    );
  }

  private _mapToDrupalUser(data: { [key: string]: unknown }) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = [{ value }];
      return acc;
    }, {} as DrupalUserEntity);
  }

  private _mapUserRegistrationRequest(payload: RegistrationRequest) {
    const localeCode = this._getLocaleCode();

    const { phone: login, password, email } = payload;

    const customCurrency = this._getCustomCurrency();

    return {
      name: login.replace('+', ''),
      pass: password,
      mail: email,
      preferred_langcode: localeCode,
      field_phone: login,
      field_forename: payload.field_forename,
      field_surname: payload.field_surname,
      currency: customCurrency,
    };
  }

  private _getLocaleCode() {
    const websiteLocale = this.appConfig.localeCode || 'en';
    const restrictedUserLocales = ['ru'];
    const localeCode = restrictedUserLocales.includes(websiteLocale)
      ? 'uk'
      : websiteLocale;

    return localeCode;
  }

  private _getCustomCurrency() {
    const { currency = 'UAH' } = this.appConfig;
    return !['USD', 'EUR', 'UAH'].includes(currency) ? currency : undefined;
  }
}
