import { MessageService } from 'primeng/api';

import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  PmFeedbackBloc,
  PmFeedbackService,
} from '@pm/feedback/data';
import { PmFeedbackFormService } from '@pm/feedback/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { PmCoreSharkService } from '@pm/core/shark';
import { PmCoreSupportChatService } from '@pm/core/support';

@Component({
    selector: 'pm-feedback-form',
    templateUrl: './pm-feedback-form.component.html',
    styleUrls: ['./pm-feedback-form.component.scss'],
    standalone: false
})
export class PmFeedbackFormComponent {
  @Input()
  form: FormGroup = this._form.getFeedbackForm();

  bloc = new PmFeedbackBloc(this._feedback);

  country = this.appConfig.mainCountry;

  constructor(
    private _form: PmFeedbackFormService,
    private _feedback: PmFeedbackService,
    private _message: MessageService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly _support: PmCoreSupportChatService
  ) {}

  onSubmit(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.markAsDirty();
    });

    if (this.form.invalid) {
      return;
    }
    const form = this.form.value;
    const domainName = this.appConfig.domain
    this.bloc.submitFeedbackForm$({
      ...form,
      domainName
    }).subscribe({
      next: (res) => {
        this._message.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Your feedback has been submitted',
        });
      },
      error: (err) => {
        this._message.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong',
        });
      },
    });
  }

  onOpenChat() {
    if (this.form.value) {
      this._support.setUserData({
        name: this.form.value.name,
        email: this.form.value.email,
        phone: this.form.value.phone,
      });
    }
    this._support.openChat();
  }
}
