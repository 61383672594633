<ng-container *transloco="let t; read: 'ui'">
  <div class="pm-ui-sectiom-hero" [style.min-height.px]="height">
    <div class="pm-ui-sectiom-hero--gradient">
      <div class="pm-ui-sectiom-hero__content">
        <div class="grid p-2 px-4 w-full py-2">
          <div class="pm-ui-sectiom-hero__content__text col-12 lg:col-8">
            <div class="pm-ui-sectiom-hero__content__text__stepper mt-2">
              @for (item of steps; track $index) {
                <div class="pm-ui-sectiom-hero__content__text__stepper--step">
                  {{ $index + 1 }} {{ item }}
                </div>
              }
            </div>
            <div class="pm-ui-sectiom-hero__content__text__title flex flex-column gap-3">
              <div class="overflow-hidden max-h-7rem">
                @if (title) {
                  <span class="text-3xl md:text-6xl font-bold">{{
                    title
                  }}</span>
                }
              </div>
              @if (subtitle) {
                <small class="line-height-3">{{ subtitle }}</small>
              }
            </div>
            <div class="pm-ui-sectiom-hero__content__text__action">
              <ng-content select="[cta]"></ng-content>
            </div>
          </div>
          @if (image) {
            <div
              class="pm-ui-sectiom-hero__content__image lg:col-4 lg:flex align-items-end justify-content-center pt-4"
              [style.height.px]="imageHeight"
              [style.background-image]="'url(' + image + ')'"
              [style.background-size]="'contain'"
              [style.background-position]="backgroundPosition"
            ></div>
          }
        </div>
      </div>
    </div>
  </div>
</ng-container>
